import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface BlogPostEditorProps {
  onChange: (content: string) => void;
  value?: string;
}

const BlogPostEditor: React.FC<BlogPostEditorProps> = ({ onChange, value }) => {
  const [content, setContent] = useState(value ? value : '');

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      ['link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'link',
    'list',
    'bullet',
  ];

  const handleContentChange = (newContent: string) => {
    setContent(newContent);
    onChange(newContent);
  };

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={content}
        onChange={handleContentChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default BlogPostEditor;