import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { lazy, Suspense } from "react";

import PageLoader from "../components/Loader/PageLoader";
import Auth from "./Auth"
import ViewSinglePost from "../pages/posts/ViewSinglePost";
import EditPost from "../pages/posts/EditPost";
import UpdateRequest from "../pages/Flights/UpdateRequests";

const AllRoutes = () => {
  const Applications = lazy(() => import("../pages/Applications"));
  const SingleApplicationsPage = lazy(() => import("../pages/Applications/SingleApplicationPage"));
  const CreateApplicationsPage = lazy(() => import("../pages/Applications/CreateApplicationPage"));
  const SchoolList = lazy(() => import("../pages/Schools/ListSchool/ListSchool"));
  const Schools = lazy(() => import("../pages/Schools/ViewSchool"));
  const Courses = lazy(() => import("../pages/Schools/ViewCourse"));
  const AddCourse = lazy(() => import("../pages/Schools/AddCourse"))
  const EditSchool = lazy(() => import("../pages/Schools/EditSchool/EditSchool"));
  const EditCourse = lazy(() => import("../pages/Schools/EditCourse/EditCourse"));
  const ForgotPassword = lazy(() => import("../pages/Authentication/ForgotPassword"));
  const ResetPassword = lazy(() => import("../pages/Authentication/ResetPassword"));
  const Login = lazy(() => import("../pages/Authentication/Login"));
  const Profile = lazy(() => import("../pages/Profile"));
  const Hotels = lazy(() => import('../pages/Hotels/Hotels'))
  const Hotel = lazy(() => import('../pages/Hotels/Hotel'))
  const Flights = lazy(() => import('../pages/Flights/Flights'))
  const Flight = lazy(() => import('../pages/Flights/Flight'))
  const Countries = lazy(() => import('../pages/Countries/Countries'))
  const Country = lazy(() => import('../pages/Countries/Country'))
  const AddCountry = lazy(() => import('../pages/Countries/AddCountry'))
  const Archives = lazy(() => import('../pages/Archive/Archives'))
  const Archive = lazy(() => import('../pages/Archive/Archive'))
  const DirectApplications = lazy(() => import('../pages/Applications/DirectApplication'))
  const DirectApplication = lazy(() => import('../pages/Applications/Application'))
  const Visas = lazy(() => import('../pages/Visas/Visas'))
  const Visa = lazy(() => import('../pages/Visas/Visa'))
  const Dashboard = lazy(() => import("../pages/Admin/Dashboard"))
  const Admins = lazy(() => import("../pages/Admin/Admins"))
  const Admin = lazy(() => import("../pages/Admin/Admin"))
  const AddAdmin = lazy(() => import("../pages/Admin/AddAdmin"))
  const Users = lazy(() => import("../pages/Admin/Users"))
  const User = lazy(() => import("../pages/Admin/User"))
  const Transactions = lazy(() => import("../pages/Transactions/Transactions"))
  const Transaction = lazy(() => import("../pages/Transactions/Transaction"))
  const Posts = lazy(() => import("../pages/posts/index"))
  const CreatePost = lazy(() => import("../pages/posts/CreatePost"))
  
  return (
    <Suspense fallback={<PageLoader />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:id" element={<User />} />
          <Route path="/application" element={<Applications />} />
          <Route path="/application/:id" element={<SingleApplicationsPage />} />
          <Route path="/application/create/:id" element={<CreateApplicationsPage />} />
          <Route path="/schools" element={<SchoolList />} />
          <Route path="/schools/view/:id" element={<Schools />} />
          <Route path="/schools/course/:id" element={<Courses />} />;
          <Route path="/schools/create" element={<EditSchool />} />;
          <Route path="/schools/edit/:id" element={<EditSchool />} />;
          <Route path="/schools/course/edit" element={<EditCourse />} />;
          <Route path="/schools/course/add" element={<AddCourse />} />;
          <Route path="/profile" element={<Profile />} />
          <Route path='/bookings' element={<Hotels />} />
          <Route path='/bookings/:id' element={<Hotel />} />
          <Route path='/update/requests' element={<UpdateRequest />} />
          <Route path='/flights' element={<Flights />} />
          <Route path='/flights/:id' element={<Flight />} />
          <Route path='/countries/' element={<Countries />} />
          <Route path='/countries/:id' element={<Country />} />
          <Route path='/countries/add' element={<AddCountry />} />
          <Route path='/countries/edit/:id' element={<AddCountry />} />
          <Route path='/archives' element={<Archives />} />
          <Route path='/archives/:id' element={<Archive />} />
          <Route path='/direct-application' element={<DirectApplications />} />
          <Route path='/direct-application/:id' element={<DirectApplication />} />
          <Route path='/visas' element={<Visas />} />
          <Route path='/visas/:id' element={<Visa />} />
          <Route element={<Auth />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/admin" element={<Admins />} />
            <Route path="/admin/:id" element={<Admin />} />
            <Route path="/admin/add" element={<AddAdmin />} />
            <Route path="/admin/edit/:id" element={<AddAdmin />} />
            <Route path='/transactions' element={<Transactions />} />
            <Route path='/transactions/:id' element={<Transaction />} />
          </Route>
          <Route path='/posts' element={<Posts />} />
          <Route path='/posts/create' element={<CreatePost />} />
          <Route path='/posts/:id' element={<ViewSinglePost />} />
          <Route path='/posts/edit/:id' element={<EditPost />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
    </Suspense>
  );
};

export default AllRoutes;
