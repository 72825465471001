import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Icon from '../Icons';
import PostsIcon from '../Icons/PostsIcon';
import MenuIcon from '../Icons/icons/MenuIcon';
import TimesIcon from '../Icons/icons/TimesIcon';

const SideNav = () => {
    const { pathname } = useLocation();
    const [openNavFull, setOpenNavFull] = useState(false)
    const activeLink = pathname.split("/")[1];
    const navList = [
        { name: "Dashboard", iconId: "dashboard-icon", tab: "/dashboard", pathName: "dashboard"},
        { name: "Bookings", iconId: "bookings-icon", tab: "/bookings", pathName: "bookings"},
        { name: "Applications", iconId: "application-icon", tab: "/application", pathName: "application"},
        { name: "Schools", iconId: "school-icon", tab: "/schools", pathName: "schools"},
        { name: "Users", iconId: "users-icon", tab: "/users", pathName: "users"},
        { name: "Admin", iconId: "admin-icon", tab: "/admin", pathName: "admin"},
        { name: "Posts", iconId: "posts-icon", tab: "/posts", pathName: "posts"},
    ]

    const handleActiveLink = (param: string) => {
        if (param === activeLink) {
          return "bg-primary rounded-[8px] text-white";
        }
        return "text-[#8B8BA4]"
    };

  return (
    <div className={`w-auto left-nav h-screen overflow-y-auto bg-white ${openNavFull ? 'pr-6' : 'pr-2'}`}>
        <div className="flex justify-end cursor-pointer text-xs w-auto my-5 mx-2" onClick={() => {setOpenNavFull(!openNavFull)}}>{openNavFull ? <TimesIcon /> : <MenuIcon />}</div>
        {openNavFull &&<Link to='/'>
            <img className="my-10 object-contain mx-auto" alt="logo" src={`${process.env.PUBLIC_URL}/static/images/OnboardLogoBlue.svg`} />
        </Link>}
        <ul className="w-full">
            { navList?.map((navLink, i) => {
                return (
                    <Link key={navLink.iconId} to={navLink.tab}>
                        <li className={`${handleActiveLink(navLink.pathName)} ${openNavFull ? 'w-[12vw]' : 'w-[3vw]'} h-[46px] font-medium text-[16px] leading-[19.84px] flex justify-start items-center gap-3 my-4 mx-4 py-4`} >
                            <span className='ml-3'>
                                {
                                    navLink.name === 'Posts' ?
                                    <PostsIcon /> :
                                    <Icon
                                        id={
                                        navLink?.pathName === activeLink
                                            ? `${navLink?.iconId}2`
                                            : navLink?.iconId
                                        }
                                        width={20}
                                        height={20}
                                    />
                                }
                            </span>
                            {openNavFull && <span className='mr-3'>{navLink.name}</span>}
                        </li>
                    </Link>
                )
            }) }
        </ul>
    </div>
  )
}

export default SideNav