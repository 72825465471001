import React, { FC } from "react";

interface Props {
  fill?: string;
}

const TimesIcon: FC<Props> = ({ fill = "#000" }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.41425 7.00022L13.7073 1.70719C14.0983 1.31719 14.0983 0.68325 13.7073 0.29325C13.3163 -0.09775 12.6842 -0.09775 12.2933 0.29325L7.00025 5.58622L1.70725 0.29325C1.31625 -0.09775 0.68425 -0.09775 0.29325 0.29325C-0.09775 0.68325 -0.09775 1.31719 0.29325 1.70719L5.58625 7.00022L0.29325 12.2933C-0.09775 12.6833 -0.09775 13.3172 0.29325 13.7072C0.48825 13.9022 0.744251 14.0002 1.00025 14.0002C1.25625 14.0002 1.51225 13.9022 1.70725 13.7072L7.00025 8.41422L12.2933 13.7072C12.4882 13.9022 12.7443 14.0002 13.0003 14.0002C13.2563 14.0002 13.5123 13.9022 13.7073 13.7072C14.0983 13.3172 14.0983 12.6833 13.7073 12.2933L8.41425 7.00022Z"
        fill={fill}
      />
    </svg>
  );
};

export default TimesIcon;
