import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from "../../redux/store"
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../../components/shared/Spinner';
import BlogPostEditor from './PostEditor';

interface Section {
  header: string;
  content: string;
  images: any;
}

const formDataToObject = (formData: FormData) => {
    const object: { [key: string]: any } = {};
  
    formData.forEach((value, key) => {
      // If the key already exists, it's an array, so push the new value.
      if (object[key]) {
        if (Array.isArray(object[key])) {
          object[key].push(value);
        } else {
          object[key] = [object[key], value];
        }
      } else {
        object[key] = value;
      }
    });
  
    return object;
};

const baseUrl = process.env.REACT_APP_BACKEND_API as string

const EditPostForm: React.FC<any> = () => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [category, setCategory] = useState('');
  const [sections, setSections] = useState<Section[]>([]);
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<any>();
  const navigate = useNavigate();
  const { authorization:{ access_token}} = useSelector((store: RootState) => store.authStore);

  useEffect(() => {
      const fetchData = async () => {
          const getPost = await axios.get(`${process.env.REACT_APP_BACKEND_API}/admin/post/view-one/${id}`, { headers: { Authorization: `Bearer ${access_token}`}})

          setTitle(getPost?.data?.data?.title);
          setSubtitle(getPost?.data?.data?.subtitle);
          setCategory(getPost?.data?.data?.category);
          setSections(getPost?.data?.data?.content)
          console.log('000', getPost?.data?.data?.content)
      } 

      fetchData();
  }, [id])

  const handleSubmit = async () => {
  
    const formData = new FormData();
    formData.append('title', title);
    formData.append('subtitle', subtitle);
    formData.append('category', category);
  
    sections.forEach((section, index) => {
      formData.append(`contents_with_headers[${index}][header]`, section.header);
      formData.append(`contents_with_headers[${index}][content]`, section.content);
      const existingImages = section.images.filter((image: any) => {
        return typeof image === 'string'
      })

      const newImages = section.images.filter((image: any) => {
        return typeof image !== 'string'
      })

      existingImages.forEach((image: any, imgIndex: any) => {
        console.log({image})
        formData.append(`contents_with_headers[${index}][existing_image_urls][${imgIndex}]`, image)
      });

      newImages.forEach((image: any, imgIndex: any) => {
        formData.append(`contents_with_headers[${index}][images]`, image);
      });
    });
  
    try {
        const sendPost = await axios.put(`${process.env.REACT_APP_BACKEND_API}/admin/post/update/${id}`, formData, { headers: { Authorization: `Bearer ${access_token}`}});

        if (sendPost?.data?.status === 'success') {
          toast.success('Post edited');
          navigate(`/posts/${id}`)
        }

        console.log(sendPost)
    } catch (error) {
        // Handle error
        console.error('Error submitting blog post', error);
        toast.error('Error submitting blog post')
    }
  };
  


  const addSection = () => {
    setSections([...sections, { header: '', content: '', images: [] }]);
  };

  const removeSection = (index: number) => {
    const newSections = [...sections];
    newSections.splice(index, 1);
    setSections(newSections);
  };

  const updateSection = (index: number, updatedSection: Partial<Section>) => {
    const newSections = [...sections];
    newSections[index] = { ...newSections[index], ...updatedSection };
    setSections(newSections);
  };

  const handleImageUpload = (index: number, files: FileList | null) => {
    if (!files) return;
    const newSections = [...sections];
    newSections[index].images = [...newSections[index].images, ...Array.from(files)];
    setSections(newSections);
  };

  const removeImage = (sectionIndex: number, imageIndex: number) => {
    const newSections = [...sections];
    newSections[sectionIndex].images.splice(imageIndex, 1);
    setSections(newSections);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-lg font-bold mb-4">Edit Blog Post</h2>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Title</label>
        <input
          type="text"
          value={post ? post?.title : title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full border border-[#000] p-4 rounded-md"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Subtitle</label>
        <input
          type="text"
          value={post ? post.subtitle :subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
          className="w-full border border-[#000] p-4 rounded-md"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Category</label>
        <select placeholder="Enter subtitle here" className="w-full border border-[#000] p-4 rounded-md" onChange={(e) => setCategory(e.target.value)} value={post ? post.category : category}>
            <option value="">Select Category</option>
            <option value="travel">Travel</option>
            <option value="education">Education</option>
            <option value="visa">Visa</option>
            <option value="lifestyle">Lifestyle</option>
        </select>
      </div>

      <div className="my-6 w-full flex justify-end">
        <button onClick={addSection} className="px-8 rounded-md h-[60px] border-[1px] border-primary text-primary">
            Add Section
        </button>
      </div>

      {sections.map((section, index) => (
        <div key={index} className="mb-6 border p-4 rounded-lg">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold">Section {index + 1}</h3>
            <button
              onClick={() => removeSection(index)}
              className="text-red-600 hover:text-red-800"
            >
              Remove Section
            </button>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Header</label>
            <input
              type="text"
              value={section.header}
              onChange={(e) => updateSection(index, { header: e.target.value })}
              className="w-full border border-[#000] p-4 rounded-md"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Content</label>
            <BlogPostEditor value={section.content} onChange={(e) => updateSection(index, { content: e })} />
            {/*<textarea
              value={section.content}
              onChange={(e) => updateSection(index, { content: e.target.value })}
              className="w-full border border-[#000] p-4 rounded-md"
              rows={4}
            />*/}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Images</label>
            <input
              type="file"
              multiple
              onChange={(e) => handleImageUpload(index, e.target.files)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
            <div className="flex mt-2 space-x-2">
              {section.images && section.images.map((image: any, imgIndex: any) => (
                <div key={imgIndex} className="relative">
                  <img
                    src={image && typeof image === 'string' && image.includes('https://') ? image : URL.createObjectURL(image)}
                    alt="Uploaded"
                    className="w-20 h-20 object-cover rounded-md"
                  />
                  <button
                    onClick={() => removeImage(index, imgIndex)}
                    className="absolute top-0 right-0 bg-red-600 text-white text-xs rounded-md p-1 w-[15px] h-[15px] text-center flex justify-center items-center"
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}

      <div className="mt-6">
        <button className="bg-[#6FA7B4] text-white px-4 py-2 rounded-md hover:bg-green-700" onClick={() => handleSubmit()}>
          {"Submit"}
        </button>
      </div>
    </div>
  );
};

export default EditPostForm;
