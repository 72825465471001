import { useState } from "react";
import PageContainerLayout from "../../layout/PageContainerLayout";
import axios from "axios";
import { useSelector } from 'react-redux';
import { RootState } from "../../redux/store"
import { toast } from "react-toastify";

const UpdateRequest = () => {
    const [showSearch, setShowSearch] = useState(true);
    const [trackingId, setTrackingId] = useState('');
    const [flight, setFlights] = useState<any>(null)
    const { authorization:{ access_token}} = useSelector((store: RootState) => store.authStore);

    const handleSearch = async () => {
        try {
            const getBooking = await axios.get(`${process.env.REACT_APP_BACKEND_API}/admin/flights/track-a-booking?tracking_id=${trackingId}`, { headers: { Authorization: `Bearer ${access_token}`}})
            console.log({getBooking})
            setFlights(getBooking.data.data);
            setShowSearch(false);
        } catch (error: any) {
            console.log(error)
            toast.error(error?.response?.data?.message)
        }
    }

    const handleConfirm = async () => {
        try {
            const thomalexReq = await axios.get(`https://rest.resvoyage.com/api/v1/public/token?clientname=9OVfb3TQqhs`);
            const thomalex_auth_token = thomalexReq.data.Token;
            console.log(thomalex_auth_token)
            const sendConfirm = await axios.put(`${process.env.REACT_APP_BACKEND_API}/admin/flights/update-confirm/${flight.id}`, {
                session_id: flight.session_id,
                thomalex_auth_token
            },
            { 
                headers: { 
                    Authorization: `Bearer ${access_token}`
                }
            })
            console.log({sendConfirm});
            setShowSearch(true);
            toast.success("Successful")
        } catch (error: any) {
            console.log(error)
            toast.error(error?.response?.data?.message)
        }
    }

    return (
        <PageContainerLayout>
            {
                showSearch &&
                <div className="flex justify-center items-center flex-col gap-3 mx-auto min-w-[60vw] my-6">
                    <input placeholder="Enter tracking ID" type="text" value={trackingId} onChange={(e) => {setTrackingId(e?.target?.value)}} className="bg-white w-full rounded-lg border border-[#000] w-full sm:w-[60vw] mx-auto h-[10vh] px-3" />
                    <button className="bg-[#6FA7B4] text-white px-4 py-2 rounded-md hover:bg-green-700" onClick={() => handleSearch()}>
                        {"Search"}
                    </button>
                </div>
            }
            {
                !showSearch && flight &&
                <div className="flex justify-center items-center my-6 w-full sm:w-[60vw] mx-auto border border-[#000] rounded-lg">
                    <div className="flex flex-col gap-2 py-5">
                        <p className="text-xs w-auto font-semibold cursor-pointer" onClick={() => {
                            setFlights(null);
                            setShowSearch(true);
                        }}>Go Back</p>
                        <p className="text-lg font-semibold">New Flight Details</p>
                        <p className="text-md">Destination: {flight?.flight_details_update?.destination}</p>
                        <p className="text-md">Departure Date: {new Date(flight?.flight_details_update?.departure_date).toLocaleString()}</p>
                        {
                            !flight?.flight_details_update?.is_confirmed &&
                            <button className="bg-[#6FA7B4] text-white px-4 py-2 rounded-md hover:bg-green-700" onClick={() => handleConfirm()}>
                                {"Confirm"}
                            </button>
                        }
                    </div>
                </div>
            }
        </PageContainerLayout>
    )
}

export default UpdateRequest;