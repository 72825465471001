const PostsIcon = () => {
    return (
        <svg 
            height="20px" 
            width="20px" 
            version="1.1" 
            id="Layer_1" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 512 512" 
            xmlSpace="preserve"
        >
            <path 
                style={{ fill: "#8B8BA4" }} 
                d="M512,467.478L512,467.478C512,492.066,492.066,512,467.478,512H44.522C19.933,512,0,492.066,0,467.478l0,0c0-24.588,19.933-44.522,44.522-44.522h422.957C492.066,422.957,512,442.89,512,467.478z"
            />
            <path 
                style={{ fill: "#8B8BA4" }} 
                d="M169.739,478.609c0,4.61-3.738,8.348-8.348,8.348H72.348c-4.61,0-8.348-3.738-8.348-8.348s3.738-8.348,8.348-8.348h89.043C166.002,470.261,169.739,473.998,169.739,478.609z M439.652,470.261H272.696c-4.61,0-8.348,3.738-8.348,8.348s3.738,8.348,8.348,8.348h166.957c4.61,0,8.348-3.738,8.348-8.348S444.262,470.261,439.652,470.261z M234.852,470.261h-1.113c-4.61,0-8.348,3.738-8.348,8.348s3.738,8.348,8.348,8.348h1.113c4.61,0,8.348-3.738,8.348-8.348S239.462,470.261,234.852,470.261z M200.348,470.261h-1.113c-4.61,0-8.348,3.738-8.348,8.348s3.738,8.348,8.348,8.348h1.113c4.61,0,8.348-3.738,8.348-8.348S204.958,470.261,200.348,470.261z"
            />
            <path 
                style={{ fill: "#8B8BA4" }} 
                d="M478.609,155.826v244.87c0,24.588-19.934,44.522-44.522,44.522H77.913c-24.588,0-44.522-19.934-44.522-44.522v-244.87c0-24.588,19.933-44.522,44.522-44.522h356.174C458.675,111.304,478.609,131.238,478.609,155.826z"
            />
            <path 
                style={{ fill: "#8B8BA4" }} 
                d="M434.087,422.957H77.913c-12.295,0-22.261-9.966-22.261-22.261v-244.87c0-12.295,9.966-22.261,22.261-22.261h356.174c12.295,0,22.261,9.966,22.261,22.261v244.87C456.348,412.99,446.382,422.957,434.087,422.957z"
            />
            <path 
                style={{ fill: "#FFFFFF" }} 
                d="M406.261,203.13H105.739c-9.22,0-16.696-7.475-16.696-16.696l0,0c0-9.22,7.475-16.696,16.696-16.696h300.522c9.22,0,16.696,7.475,16.696,16.696l0,0C422.957,195.655,415.481,203.13,406.261,203.13z M244.87,244.87L244.87,244.87c0-9.22-7.475-16.696-16.696-16.696H105.739c-9.22,0-16.696,7.475-16.696,16.696l0,0c0,9.22,7.475,16.696,16.696,16.696h122.435C237.395,261.565,244.87,254.09,244.87,244.87z M422.957,244.87L422.957,244.87c0-9.22-7.475-16.696-16.696-16.696H283.826c-9.22,0-16.696,7.475-16.696,16.696l0,0c0,9.22,7.475,16.696,16.696,16.696h122.435C415.482,261.565,422.957,254.09,422.957,244.87z"
            />
            <path 
                style={{ fill: "#FFFFFF" }} 
                d="M406.261,384H105.739c-9.22,0-16.696-7.475-16.696-16.696v-66.783c0-9.22,7.475-16.696,16.696-16.696h300.522c9.22,0,16.696,7.475,16.696,16.696v66.783C422.957,376.525,415.482,384,406.261,384z"
            />
            <path 
                style={{ fill: "#FFFFFF" }} 
                d="M139.688,374.23l-2.332,2.332l0.086,0.086l-19.098,19.098c-1.37,1.37-3.229,2.14-5.167,2.14l0,0c-1.938,0-3.797-0.77-5.167-2.14l-19.098-19.098l0.086-0.086l-2.332-2.332c-6.677-6.677-6.677-17.501,0-24.179l0,0c6.677-6.677,17.501-6.677,24.179,0l2.332,2.332l2.332-2.332c6.677-6.677,17.501-6.677,24.179,0l0,0C146.365,356.728,146.365,367.553,139.688,374.23z"
            />
            <path 
                style={{ fill: "#8B8BA4" }} 
                d="M376.209,292.36l-22.261,29.682c-4.452,5.936-13.357,5.936-17.809,0l-22.261-29.682c-1.445-1.927-2.226-4.27-2.226-6.678V22.261C311.652,9.966,321.618,0,333.913,0h22.261c12.295,0,22.261,9.966,22.261,22.261v263.421C378.435,288.089,377.653,290.433,376.209,292.36z"
            />
            <path 
                style={{ fill: "#8B8BA4" }} 
                d="M370.087,300.522l-16.139,21.518c-4.452,5.936-13.357,5.936-17.809,0L320,300.522H370.087z"
            />
            <path 
                style={{ fill: "#8B8BA4" }} 
                d="M311.652,256V22.261C311.652,9.966,321.618,0,333.913,0h22.261c12.295,0,22.261,9.966,22.261,22.261V256H311.652z"
            />
            <path 
                style={{ fill: "#8B8BA4" }} 
                d="M345.043,256V0h11.13c12.295,0,22.261,9.966,22.261,22.261V256H345.043z"
            />
            <path 
                style={{ fill: "#8B8BA4" }} 
                d="M378.435,22.261v22.261h-66.783V22.261C311.652,9.966,321.618,0,333.913,0h22.261C368.469,0,378.435,9.966,378.435,22.261z"
            />
        </svg>
    );
};

export default PostsIcon;
